.navbar {
    background-color:#000;
    height: 75px;
    position: fixed;
    top:0;
    width: 100%;
    z-index: 999;
}

.navbar ul {
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar ul li {
    padding: 24px;
    text-align: center;
    width: 110px;
}

.home {
    border-bottom: 10px #000 solid;
}

.gallery {
    border-bottom: 10px yellow solid;
}

.code {
    border-bottom: 10px cyan solid;
}

.blog {
    border-bottom: 10px magenta solid;
}

.navbar ul li a {
    color: #fff;
}