.nanumGothicRegular {
    font-family: "Nanum Gothic", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
  }

section {
    background-color: #fff;
    margin: 16px 32px;
}