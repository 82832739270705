.codeSection {
    background-color: cyan;
    margin: 0;
    padding: 16px 32px;
    font-family: "Nanum Gothic", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;

    h1 {
        font-size: 52px;
    }
}