.galleryContainer {
    margin: 24px auto;
    max-width: 1000px;
}

.artSection {
    background-color: yellow;
    margin: 0;
    padding: 16px 32px;
    font-family: "Nanum Gothic", sans-serif;
    font-weight: 400;
    
    h1 {
        font-size: 52px;
        margin: 0;
    }

    p {
        font-size: 24px;
    }

    a {
        color: #6A3300;
    }
}